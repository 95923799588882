import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLazyElectricbusesQuery } from '@shared/api/services/electricbus/enhanced';
import {
  useGetBatteryTelemetry,
  useLazyGetBatteryTelemetry,
  useLazyGetTelemetryQuery,
} from '@shared/api/services/telemetry/enhanced';
import { QUERY_PARAM } from '@shared/consts/server';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';
import {
  BatteryTelemetryErrorBaseOutput,
  BatteryTelemetryWithErrorsOutput,
} from '@shared/api/services/telemetry/api';
import { BatteriesTelemetryData, VehiclesTelemetryData } from '../consts/types';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { useElectricbusesData } from '@entities/vehicle/hooks/use-electricbuses-data';
import { preprocessGroupIdsArg } from '@entities/vehicle/lib/preprocess-group-ids-arg';

export const useData = () => {
  const {
    tableData: electricbuses,
    isLoading: isElectricbusesLoading,
    isSuccess: isElectricbusesSuccess,
    error: electricbusesError,
  } = useElectricbusesData({
    groupIds: preprocessGroupIdsArg('TRANSPORT'),
  });

  const {
    currentData: batteriesTelemetry,
    isLoading: isBatteriesTelemetryLoading,
    error: isBatteriesTelemetryError,
    isSuccess: isBatteriesTelemetrySuccess,
  } = useGetBatteryTelemetry({ perPage: 300 }, { pollingInterval: 5000 });

  const vehiclesTelemetryTableData: VehiclesTelemetryData = useMemo(() => {
    if (
      electricbuses === undefined ||
      electricbuses === null ||
      batteriesTelemetry === undefined ||
      batteriesTelemetry === null
    ) {
      return [];
    }

    return electricbuses
      .filter((electrobusItem) => electrobusItem.imei !== undefined)
      .map((electrobusItem) => {
        const trackerImei = electrobusItem.imei;
        const currentTelemetry = electrobusItem.telemetry;
        const park_name = electrobusItem.park_name;

        console.log('electricbuses', electrobusItem);

        const batteries =
          batteriesTelemetry.filter((battery) => {
            return battery.telemetry_id === currentTelemetry?.id;
          }) ?? [];

        const errors = batteries.reduce((allErrors, { errors }) => {
          const processedErrors = errors ?? [];
          return [...allErrors, ...processedErrors];
        }, [] as BatteryTelemetryErrorBaseOutput[]);

        return {
          park_name: park_name,
          imei: trackerImei!,
          grz: electrobusItem.grn,
          garage_number: electrobusItem.garage_number,
          time: currentTelemetry?.time,
          coordinates: {
            latitude: currentTelemetry?.latitude,
            longitude: currentTelemetry?.longitude,
          },
          firmware: electrobusItem.firmware,
          soc: currentTelemetry?.soc,
          soh: currentTelemetry?.soh,
          charge_current_A: currentTelemetry?.charge_current_A,
          discharge_current_A: currentTelemetry?.discharge_current_A,
          permissible_charge_current:
            currentTelemetry?.permissible_charge_current,
          permissible_discharge_current:
            currentTelemetry?.permissible_discharge_current,
          power_reserve: currentTelemetry?.power_reserve,
          last_charge: currentTelemetry?.last_charge,
          ctts: currentTelemetry?.ctts,
          interval_telemetry_S: currentTelemetry?.interval_telemetry_S,
          count_charge_session: currentTelemetry?.count_charge_session,
          errors,
          batteries,
        };
      })
      .sort((a, b) => Number(a.imei) - Number(b.imei));
  }, [electricbuses, batteriesTelemetry]);

  const batteriesTelemetryTableData = useMemo(() => {
    const ebusesData = electricbuses;

    if (
      ebusesData === undefined ||
      ebusesData === null ||
      batteriesTelemetry === undefined ||
      batteriesTelemetry === null
    ) {
      return [];
    }

    const result = vehiclesTelemetryTableData.reduce(
      (batteriesData, vehicle) => {
        if (vehicle.batteries.length === 0) {
          return batteriesData;
        } else {
          const preparedBatteries = vehicle.batteries.map((battery) => ({
            imei: vehicle.imei,
            grz: vehicle.grz,
            garage_number: vehicle.garage_number,
            ...battery,
          }));
          return [...batteriesData, ...preparedBatteries];
        }
      },
      [] as BatteriesTelemetryData
    );

    return result;
  }, [vehiclesTelemetryTableData, batteriesTelemetry]);

  const error = [electricbusesError, isBatteriesTelemetryError].find(
    (err) => err !== undefined
  );

  const isLoading = isElectricbusesLoading || isBatteriesTelemetryLoading;

  const isSuccess = isElectricbusesSuccess && isBatteriesTelemetrySuccess;

  return {
    vehiclesTelemetryTableData,
    batteriesTelemetryTableData,
    isLoading,
    error,
    isSuccess,
    //  defaultPageSize,
  };
};
