import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import { VehicleTableType } from '../model/vehicle-table-type';
import { GetHotStorageApiHotStorageGetApiResponse } from '@shared/api/services/telemetry/api';
import { GetElectricbusesApiElectricbusesGetApiResponse } from '@shared/api/services/electricbus/api';

export const getLngLat = ({
  mapType,
  parks,
  electrobuses,
  data,
  eParkId,
  convoyId,
  eBusId,
}: {
  mapType: VehicleTableType | null;
  parks?: GetParksApiParksGetApiResponse;
  electrobuses?: GetElectricbusesApiElectricbusesGetApiResponse;
  data?: GetHotStorageApiHotStorageGetApiResponse;
  eParkId?: string;
  convoyId?: string;
  eBusId?: string;
}): { initialLatitude?: number; initialLongitude?: number } => {
  let firstEbusGrn: string | undefined;
  if (mapType === 'PARK') {
    const currentElectricBusPark = parks?.find(
      ({ id }) => String(id) === String(eParkId)
    );
    const [firstConvoy] = currentElectricBusPark?.groups ?? [];

    firstEbusGrn =
      electrobuses?.find(({ group_id }) => group_id === firstConvoy.id)?.grn ??
      '';
  }

  if (mapType === 'CONVOY') {
    const currentConvoy = parks
      ?.find(({ id }) => String(id) === String(eParkId))
      ?.groups?.find(({ id }) => String(id) === convoyId);

    firstEbusGrn =
      electrobuses?.find(({ group_id }) => group_id === currentConvoy?.id)
        ?.grn ?? '';
  }

  if (mapType === 'ELECTROBUS') {
    firstEbusGrn = electrobuses?.find(({ id }) => id === eBusId)?.grn ?? '';
  }

  const targetGrn = firstEbusGrn;

  const initialLatitude = data?.find(
    ({ ev }) => ev?.grn === firstEbusGrn
  )?.latitude;
  const initialLongitude = data?.find(
    ({ ev }) => ev?.grn === firstEbusGrn
  )?.longitude;

  return {
    initialLatitude,
    initialLongitude,
  };
};
