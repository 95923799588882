import { FC, useState } from 'react';
import { UpCircleTwoTone, DownCircleTwoTone } from '@ant-design/icons';

import { BatteryTelemetryErrorBaseOutput } from '@shared/api/services/telemetry/api';
import { BatteryErrorsPanelWrapper, ErrorsList, IconWrapper } from './styles';

type Props = {
  errors: BatteryTelemetryErrorBaseOutput[];
};

export const BatteryErrorsPanel: FC<Props> = ({ errors }) => {
  const [isExpand, toggleExpand] = useState(false);
  const isSingle = errors.length === 1;

  const result = errors.map(
    ({ battery_telemetry_id, code_error, text_error }) => {
      return (
        <div key={`${battery_telemetry_id}-${code_error}-${text_error}`}>
          {code_error}: {text_error}
        </div>
      );
    }
  );

  return (
    <BatteryErrorsPanelWrapper>
      {isSingle ? null : isExpand ? (
        <IconWrapper>
          <UpCircleTwoTone width={20} onClick={() => toggleExpand(false)} />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <DownCircleTwoTone width={20} onClick={() => toggleExpand(true)} />
        </IconWrapper>
      )}
      <ErrorsList isSingleChange={isSingle}>
        {isExpand ? result : result[0]}
      </ErrorsList>
    </BatteryErrorsPanelWrapper>
  );
};
