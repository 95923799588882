import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { TrackerOutput } from '@shared/api/services/tracker/api';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { TrackerStatusBadge } from '@entities/vehicle/ui/tracker-status-badge';
import { GetGroupsApiTrackersModelsGetApiResponse } from '@shared/api/services/common/api';
import { EditTrackerButton } from '@features/trackers/edit/ui/button';
import { HeaderCheckbox, SelectTrackerCheckbox } from './styles';
import dayjs from 'dayjs';
import { useRole } from '@entities/user/lib/hooks/use-role';
import { useVehicleData } from '@entities/vehicle/hooks/use-vehicle-data';
import { getParkNameByGroupId } from '@entities/vehicle/lib/get-park-name-by-group-id';

const columnHelper = createColumnHelper<TrackerOutput>();

type Props = {
  models?: GetGroupsApiTrackersModelsGetApiResponse;
};

export const useColumns = ({ models }: Props) => {
  // TODO: вынести хуки ниже в отдельный кастомный хук

  const {
    isDispatcher,
    isAdmin,
    isOperator,
    isLoading: isRoleLoading,
  } = useRole();

  const {
    parks,
    electricbuses,
    isLoading: isParksOrElectricbusesLoading,
    isSuccess: isParksOrElectricbusesSuccess,
    error: parksOrElectricbusesError,
  } = useVehicleData({});

  console.log('parks', parks);
  console.log('electricbuses', electricbuses);

  const [settings, setSettings] = useState<Record<string, boolean>>({
    imei: true,
    park: true,
    phone: false,
    model_id: true,
    firmware: true,
    status: true,
    last_online: true,
  });

  const selectTrackerColumn = useMemo(() => {
    // @ts-ignore
    return columnHelper.accessor('', {
      id: 'select_tracker',
      size: 20,
      maxSize: 20,
      minSize: 20,
      header: ({ table }) => {
        if (isRoleLoading) {
          return null;
        }

        if (isDispatcher || isAdmin) {
          return (
            <HeaderCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        }

        return null;
      },
      cell: ({ row }) => {
        if (isRoleLoading) {
          return null;
        }

        if (isDispatcher || isAdmin) {
          return (
            <SelectTrackerCheckbox
              disabled={!row.getCanSelect()}
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        }

        return null;
      },
    });
  }, [isDispatcher, isAdmin, isOperator, isRoleLoading]);

  const DATA_COLUMNS = useMemo(() => {
    return [
      columnHelper.accessor('imei', {
        id: 'imei',
        header: 'ID трекера',
        size: 135,
        maxSize: 135,
        minSize: 135,
        cell: (props) => {
          const stationId = props.getValue();
          return stationId ?? 'Нет данных';
        },
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: 'Статус',
        size: 170,
        maxSize: 170,
        minSize: 170,
        cell: (props) => {
          const val = props.getValue();

          if (val === 'ONLINE' || val === 'OFFLINE') {
            return <TrackerStatusBadge status={val} />;
          }

          return '';
        },
        enableSorting: false,
      }),
      // @ts-ignore
      columnHelper.accessor('phone_number', {
        id: 'phone',
        header: 'Телефон', // выяснить у Володи группу
        size: 100,
        maxSize: 100,
        minSize: 100,
        cell: (props) => {
          return props.getValue() ?? 'Нет данных';
        },
        enableSorting: false,
      }),
      columnHelper.accessor('last_online', {
        id: 'last_online',
        header: 'Был в сети',
        size: 90,
        maxSize: 90,
        minSize: 90,
        cell: (props) => {
          const value = props.getValue();

          if (value === undefined || value === null) {
            return 'Нет данных';
          }

          // const formattedDate = value?.includes('Z') ? value : `${value}Z`;
          const [date, time] = dayjs(value)
            .format('YYYY.MM.DD HH:mm')
            .split(' ');

          return `${time},  ${date}`;
        },
        enableSorting: true,
      }),
      columnHelper.accessor('ev_id', {
        id: 'park',
        header: 'Парк',
        size: 120,
        maxSize: 120,
        minSize: 120,
        cell: (props) => {
          const evId = props.getValue();
          if (!evId) {
            return 'Нет данных';
          }

          const groupId = electricbuses?.find(
            ({ id }) => evId === id
          )?.group_id;

          if (groupId) {
            return getParkNameByGroupId(groupId, parks);
          }
          return 'Нет данных';
        },
      }),
      columnHelper.accessor('model_id', {
        id: 'model_id',
        header: 'Модель', // выяснить у Володи группу
        size: 90,
        maxSize: 90,
        minSize: 90,
        cell: (props) => {
          const modelId = props.getValue();
          const modelName = models?.find(({ id }) => id === modelId)?.name;

          return modelName ?? modelId ?? 'Нет данных';
        },
        enableSorting: true,
      }),
      columnHelper.accessor('firmware', {
        id: 'firmware',
        header: 'Прошивка',
        size: 90,
        maxSize: 90,
        minSize: 208,
        cell: (props) => {
          return props.getValue() ?? 'Нет данных';
        },
        enableSorting: true,
      }),
    ];
  }, [parks, electricbuses, isDispatcher, isAdmin, isOperator, isRoleLoading]);

  const settingsColumn = useMemo(() => {
    return getTableSettingsColumn({
      columnHelper,
      columns: DATA_COLUMNS.map(({ id, header }) => {
        const typedId = id!;
        return {
          key: id as string,
          label: header as string,
          isChecked: settings[typedId],
        };
      }),
      settings,
      renderCell: (props) => {
        if (isRoleLoading) {
          return null;
        }

        if (isDispatcher || isAdmin) {
          return <EditTrackerButton tracker={props.row.original} />;
        }

        return null;
      },
      setVisibleColumns: (cols: Record<string, boolean>) => {
        setSettings(cols);
      },
    });
  }, [isDispatcher, isAdmin, isOperator, isRoleLoading]);

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [selectTrackerColumn, ...dataCols, settingsColumn];
  }, [
    settings,
    parks,
    electricbuses,
    isDispatcher,
    isAdmin,
    isOperator,
    isRoleLoading,
  ]);

  return visibleColumns;
};
