import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { QUERY_PARAM } from '@shared/consts/server';
import { ServerTableLayout } from '@shared/ui/table/layout';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';

type Props = {
  batteriesData: BatteriesTelemetryData;
  count: number;
  loading: boolean;
  defaultPageSize: number;
};
import { BatteriesTelemetryData } from '../consts/types';
import { useBatteryTelemetryColumns } from '../hooks/use-battery-telemetry-columns';

export const BatteriesTelemetryTableInstanse: FC<Props> = ({
  batteriesData,
  count,
  loading,
  defaultPageSize,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const size = searchParams.get(QUERY_PARAM.limit);
  const page = searchParams.get(QUERY_PARAM.page);
  const sortedField = searchParams.get(QUERY_PARAM.ordering);
  const sortedBy = searchParams.get(QUERY_PARAM.type);

  const columns = useBatteryTelemetryColumns();

  const sorting = useSortingState();
  const pagination = usePaginationState();

  useEffect(() => {
    setSearchParams({});
  }, []);

  const sortedData = useMemo(() => {
    const clonedBatteriesData = JSON.parse(
      JSON.stringify(batteriesData)
    ) as BatteriesTelemetryData;

    if (sortedBy === 'asc' && sortedField !== null) {
      return clonedBatteriesData.sort(
        (a, b) => a[sortedField] - b[sortedField]
      );
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      return clonedBatteriesData.sort(
        (a, b) => b[sortedField] - a[sortedField]
      );
    }

    return clonedBatteriesData;
  }, [batteriesData, sortedField, sortedBy]);

  const paginatedData = useMemo(() => {
    const currentPage = Number(page ?? 1);
    const currentSize = Number(size ?? defaultPageSize);

    const startIdx = (currentPage - 1) * currentSize;
    const endIdx = startIdx + currentSize;
    return sortedData.slice(startIdx, endIdx);
  }, [sortedData, size, page]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting,
      pagination,
    },
    getRowId: (row) => `${row.imei}-${row.number_battery}`,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      loading={loading}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
};
