import { HeaderLayout } from '../layout';
import { ContentWrapper, Outer, ChargingStationsTitle } from './styles';
import { useParams } from 'react-router-dom';
import { useVehicleData } from '@entities/vehicle/hooks/use-vehicle-data';

export function ElectrobusExpandedInfoPageHeader() {
  const { eParkId, convoyId, eBusId } = useParams();

  const { parks, electricbuses, isSuccess } = useVehicleData({});

  const park = parks?.find(({ id }) => Number(id) === Number(eParkId));
  const convoy = park?.groups?.find(({ id }) => String(id) === convoyId);
  const ebus = electricbuses?.find(({ id }) => id === eBusId);

  const title = `${park?.name ?? ''}, ${convoy?.name ?? ''}, ${
    ebus?.grn ?? ''
  }`;

  const content = (
    <ContentWrapper>
      <ChargingStationsTitle>{isSuccess ? title : ''}</ChargingStationsTitle>
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
}
