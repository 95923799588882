import { useMemo } from 'react';
import { ElectricBusTableData, VehicleFilterType } from '../consts/types';

type Props = {
  tableData: ElectricBusTableData;
  filters: VehicleFilterType;
};

export const useFilterData = ({ tableData, filters }: Props) => {
  const {
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    powerReserveFrom,
    powerReserveTo,
  } = filters;

  const filteredData = useMemo(() => {
    return tableData.filter((tableDataItem) => {
      const { soc, soh, power_reserve, manufacturer, model, status, type } =
        tableDataItem;

      const bySocFrom =
        socFrom !== undefined
          ? soc !== undefined && soc !== null && soc >= socFrom
          : true;
      const bySocTo =
        socTo !== undefined
          ? soc !== undefined && soc !== null && soc <= socTo
          : true;
      const bySohFrom =
        sohFrom !== undefined
          ? soh !== undefined && soh !== null && soh >= sohFrom
          : true;
      const bySohTo =
        sohTo !== undefined
          ? soh !== undefined && soh !== null && soh <= sohTo
          : true;
      const byPowerReserveFrom =
        powerReserveFrom !== undefined
          ? power_reserve !== undefined &&
            power_reserve !== null &&
            power_reserve >= powerReserveFrom
          : true;
      const byPowerReserveTo =
        powerReserveTo !== undefined
          ? power_reserve !== undefined &&
            power_reserve !== null &&
            power_reserve <= powerReserveTo
          : true;
      const byManufacturerIds = manufactureIds.length
        ? manufacturer?.id !== undefined &&
          manufacturer?.id !== null &&
          manufactureIds.includes(manufacturer.id)
        : true;
      const byModelsIds = modelsIds.length
        ? model?.id !== undefined &&
          model?.id !== null &&
          modelsIds.includes(model.id)
        : true;
      const byStatusIds = statusEvIds.length
        ? status?.id !== undefined &&
          status.id !== null &&
          statusEvIds.includes(status.id)
        : true;
      const byVehicleType =
        typeId !== undefined
          ? type?.id !== undefined && type?.id !== null && type.id === typeId
          : true;

      return (
        bySocFrom &&
        bySocTo &&
        bySohFrom &&
        bySohTo &&
        byPowerReserveFrom &&
        byPowerReserveTo &&
        byManufacturerIds &&
        byModelsIds &&
        byStatusIds &&
        byVehicleType
      );
    });
  }, [
    tableData,
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    powerReserveFrom,
    powerReserveTo,
  ]);

  return filteredData;
};
